<template>

  <div>
    <div class="svg-background  typography" style="height:100vh;overflow:hidden">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="swiper-wrapper d-none d-xl-block"
              style="position:absolute; left: 5%;top: 50%;transform: translate(-5%, -50%);">

              <div style="width: 420px;">
                <div class="container" style="flex-grow: 1;text-align: start;">
                  <img alt="crowdyvest" src="/images/logo.png" style="color:white;width: 85px;height: 80px;"
                    class="logo-icon">
                  <p class="logan"
                    style="padding-top: 50px;max-width: 390px;text-align: left;font-style: normal;font-size: 50px;line-height: 112.6%;color: #fff;">
                    Simple digital address...</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-login-register text-white">

              <div class="form-login mb-0">
                <form>

                  <div class="font-size-md text-white mb-5">
                    <div class="logan-container">
                      <p class="logan-subtitle" style="margin-top: 0;font-size: 25px;color: #fff;margin-bottom: 40px;">
                      Reset Password</p>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <label for="email" class="sr-only">E-Mail Address</label>
                    <input id="email" type="text" class="form-control font-size-lg border-0 form-control-lg bg-transparent login-input" :class="{ 'is-invalid': errors.code }"
                      v-model="details.email" placeholder="Enter email" :disabled="isLoading ? '' : isLoading" />

                    <div class="invalid-feedback" v-if="errors.email">
                      {{ errors.email[0] }}
                    </div>
                  </div>
                
                  <div class="form-group mt-6" v-if="errors.email">

                    <div class="alert alert-warning mb-4 p-0 alert-dismissible fade show" role="alert">

                      <div class="message"> {{ errors.email[0] }}</div>
                      <a href="#" class="alert-button close" data-dismiss="alert" aria-label="Close"><i
                          class="fal fa-times"></i></a>
                    </div>
                  </div>

                  
                  <div class="form-group mt-6" v-if="isSuccess">

                    <div class="alert alert-success mb-4 p-0 alert-dismissible fade show" role="alert">

                      <div class="message"> We have e-mailed your password reset link! </div>
                      <a href="#" class="alert-button close" data-dismiss="alert" aria-label="Close"><i
                          class="fal fa-times"></i></a>
                    </div>
                  </div>

                  <button type="button" @click="sendResetLink" :disabled="isLoading ? '' : isLoading"
                    class="btn btn-primary btn-block font-weight-bold  font-size-lg rounded-sm">
                    <span v-if="!isLoading">  Send Reset Link </span>
                    <span v-if="isLoading">
                      <i class="fas fa-circle-notch fa-spin"></i> Sending...</span>
                  </button>

                </form>


              </div>



            </div>
          </div>
        </div>
      </div>

    </div>
    <br>
  </div>

</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  export default {
    name: "ForgetPassword",

    data() {
      return {
        isLoading:false,
        isResending:false,
        details: {
          email: null,
        },
        success: null,
        error: null,
      };
    },
components: {
    },
    computed: {
      ...mapGetters("auth", ["user"]),
      ...mapGetters(["errors", "isSuccess"])
    },

    mounted() {
      this.$store.commit("setErrors", {});
      this.$store.commit("setIsSuccess", false);
    },
    methods: {
      ...mapActions("auth", ["sendResetLinkRequest"]),

      sendResetLink: function () {
        this.isLoading=true;
        this.sendResetLinkRequest(this.details).then(() => {
           this.isLoading=false;
          // if (this.isSuccess) {

          //   if (this.user.hasBusiness) {
          //     this.$router.push({
          //       name: "Home"
          //     }).catch(() => {});
          //   } else {
          //     this.$router.push({
          //       name: "CreateBusiness"
          //     }).catch(() => {});
          //   }


         // }
        });
      }
    },
  };
</script>