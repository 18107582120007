<template>

  <div>
    <div class="svg-background  typography" style="height:100vh;overflow:hidden">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="swiper-wrapper d-none d-xl-block"
              style="position:absolute; left: 5%;top: 50%;transform: translate(-5%, -50%);">

              <div style="width: 420px;">
                <div class="container" style="flex-grow: 1;text-align: start;">
                  <img alt="crowdyvest" src="/images/logo.png" style="color:white;width: 85px;height: 80px;"
                    class="logo-icon">
                  <p class="logan"
                    style="padding-top: 50px;max-width: 390px;text-align: left;font-style: normal;font-size: 50px;line-height: 112.6%;color: #fff;">
                    Simple digital address...</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-login-register text-white">

              <div class="form-login mb-0">
                <form>

                  <div class="font-size-md text-white mb-5">
                    <div class="logan-container">
                      <p class="logan-subtitle" style="margin-top: 0;font-size: 25px;color: #fff;margin-bottom: 40px;">
                       Reset Password Now..</p>
                    </div>
                  </div>
            
                  <div class="form-group mb-3">
                    <label for="password" class="sr-only">New Password</label>

                    <input id="password" type="password" class="form-control font-size-lg border-0 form-control-lg bg-transparent login-input"
                      :class="{ 'is-invalid': errors.password }" v-model="details.password"
                      :disabled="isLoading ? '' : isLoading" placeholder="Password" />

                    <div class="invalid-feedback" v-if="errors.password">
                      {{ errors.password[0] }}
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label for="password_confirmation" class="sr-only">Confirm password</label>

                    <input id="password_confirmation" type="password" class="form-control font-size-lg border-0 form-control-lg bg-transparent login-input"
                      :class="{ 'is-invalid': errors.password_confirmation }" v-model="details.password_confirmation"
                      :disabled="isLoading ? '' : isLoading" placeholder="Confirm password" />

                    <div class="invalid-feedback" v-if="errors.password_confirmation">
                      {{ errors.password_confirmation[0] }}
                    </div>
                  </div>
                 

                   <div class="form-group mt-6" v-if="errors.password">

                    <div class="alert alert-warning mb-4 p-0 alert-dismissible fade show" role="alert">

                      <div class="message"> <span v-for="password in errors.password" :key="password"> *. {{ password }} <br>  </span>   </div>
                      <a href="#" class="alert-button close" data-dismiss="alert" aria-label="Close"><i
                          class="fal fa-times"></i></a>
                    </div>
                  </div>


                   <div class="form-group mt-6" v-if="!errors.success && errors.message">

                    <div class="alert alert-warning mb-4 p-0 alert-dismissible fade show" role="alert">

                      <div class="message"> {{ errors.message }}</div>
                      <a href="#" class="alert-button close" data-dismiss="alert" aria-label="Close"><i
                          class="fal fa-times"></i></a>
                    </div>
                  </div>


                  <button style="  border-radius: 6px;--background-activated: #54ab68!important;" type="submit"
                    @click="resetPassword" @enter="resetPassword" :disabled="isLoading ? '' : isLoading"
                    class="btn btn-primary btn-block font-weight-bold  font-size-lg rounded-sm">
                    <span v-if="!isLoading">Reset Password</span>
                    <span v-if="isLoading"> <i class="fas fa-circle-notch fa-spin"></i> Resetting ...</span>
                  </button>
               

                </form>



              </div>



            </div>
          </div>
        </div>
      </div>

    </div>
    <br>
  </div>

</template>


<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  export default {
    name: "ResetPassword",

    data: function () {
      return {
        isLoading: false,
        details: {
          password: null,
          password_confirmation: null,
          token:''
        }
      };
    },
    components: {
    },
    computed: {
      ...mapGetters(["errors", "isSuccess"])
    },

    mounted() {
      this.$store.commit("setErrors", {});
      this.$store.commit("setIsSuccess", false);
    },


    methods: {
      ...mapActions("auth", ["sendChangePasswordRequest"]),

      resetPassword: function () {
        this.isLoading = true;
        this.details.token=this.$route.params.token;
        this.sendChangePasswordRequest(this.details).then(() => {
             this.isLoading = false;
          if (this.isSuccess) {
            this.details = {};
            
              this.$router.push({
                name: "Home"
              }).catch(() => {});
            


          }

        });
      }
    }
  };
</script>
<style>
  .invalid-feedback {
    color: #ffff !important
  }
</style>