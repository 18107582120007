<template>

  <div>
    <div class="svg-background  typography" style="height:100vh;overflow:hidden">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="swiper-wrapper d-none d-xl-block"
              style="position:absolute; left: 5%;top: 50%;transform: translate(-5%, -50%);">

              <div style="width: 420px;">
                <div class="container" style="flex-grow: 1;text-align: start;">
                  <img alt="crowdyvest" src="/images/logo.png" style="color:white;width: 85px;height: 80px;"
                    class="logo-icon">
                  <p class="logan"
                    style="padding-top: 50px;max-width: 390px;text-align: left;font-style: normal;font-size: 50px;line-height: 112.6%;color: #fff;">
                    Simple digital address...</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-login-register text-white">

              <div class="form-login mb-0">
                <form>

                  <div class="font-size-md text-white mb-5">
                    <div class="logan-container">
                      <p class="logan-subtitle" style="margin-top: 0;font-size: 25px;color: #fff;margin-bottom: 40px;">
                        Create Your Account</p>
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <label for="firstName" class="sr-only">First name</label>
                    <input id="firstName" type="text" class="form-control font-size-lg border-0 form-control-lg bg-transparent login-input"
                      :class="{ 'is-invalid': errors.firstName }" v-model="details.firstName"
                      placeholder="Enter your first name" :disabled="isLoading ? '' : isLoading" />

                    <div class="invalid-feedback" v-if="errors.firstName">
                      {{ errors.firstName[0] }}
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <label for="lastName" class="sr-only">Last name</label>
                    <input id="lastName" type="text" class="form-control font-size-lg border-0 form-control-lg bg-transparent login-input"
                      :class="{ 'is-invalid': errors.lastName }" v-model="details.lastName"
                      placeholder="Enter your last name" :disabled="isLoading ? '' : isLoading" />

                    <div class="invalid-feedback" v-if="errors.lastName">
                      {{ errors.lastName[0] }}
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label for="email" class="sr-only">Email address</label>
                    <input id="email" type="text" class="form-control font-size-lg border-0 form-control-lg bg-transparent login-input"
                      :class="{ 'is-invalid': errors.email }" v-model="details.email" placeholder="Enter email"
                      :disabled="isLoading ? '' : isLoading" />

                    <div class="invalid-feedback" v-if="errors.email">
                      {{ errors.email[0] }}
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label for="password" class="sr-only">Password</label>

                    <input id="password" type="password" class="form-control font-size-lg border-0 form-control-lg bg-transparent login-input"
                      :class="{ 'is-invalid': errors.password }" v-model="details.password"
                      :disabled="isLoading ? '' : isLoading" placeholder="Password" />

                    <div class="invalid-feedback" v-if="errors.password">
                      {{ errors.password[0] }}
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label for="password_confirmation" class="sr-only">Confirm password</label>

                    <input id="password_confirmation" type="password" class="form-control font-size-lg border-0 form-control-lg bg-transparent login-input"
                      :class="{ 'is-invalid': errors.password_confirmation }" v-model="details.password_confirmation"
                      :disabled="isLoading ? '' : isLoading" placeholder="Confirm password" />

                    <div class="invalid-feedback" v-if="errors.password_confirmation">
                      {{ errors.password_confirmation[0] }}
                    </div>
                  </div>

                  <div class="form-group mt-6" v-if="errors.message">

                        <MessageErrorFadeOut v-bind:message="errors.message" v-bind:show="errors && errors.message?true:false" />
                       
                    </div>

                  <button style="  border-radius: 6px;--background-activated: #54ab68!important;" type="button"
                    @click="register" @enter="register" :disabled="isLoading ? '' : isLoading"
                    class="btn btn-primary btn-block font-weight-bold  font-size-lg rounded-sm">
                    <span v-if="!isLoading">Sign up</span>
                    <span v-if="isLoading"> <i class="fas fa-circle-notch fa-spin"></i> Signing
                      up...</span>
                  </button>
                  <p
                    style="font-style: normal;font-weight: 400;font-size: 14px!important;text-align: center;color: #fff;margin-top: 20px;">
                    Already have an account?
                    <router-link style="color: #9c9;font-weight: 500;" to="/login">Sign in</router-link>
                  </p>

                </form>



              </div>



            </div>
          </div>
        </div>
      </div>

    </div>
    <br>
  </div>

</template>


<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
import MessageErrorFadeOut from "../Common/Message/MessageErrorFadeOut"
  export default {
    name: "Register",

    data: function () {
      return {
        isLoading: false,
        details: {
          firstName: null,
          lastName: null,
          email: null,
          password: null,
          password_confirmation: null
        }
      };
    },
    components: {
      MessageErrorFadeOut
    },
    computed: {
      ...mapGetters(["errors", "isSuccess"])
    },

    mounted() {
      this.$store.commit("setErrors", {});
      this.$store.commit("setIsSuccess", false);
    },


    methods: {
      ...mapActions("auth", ["sendRegisterRequest"]),

      register: function () {
        this.isLoading = true;
        this.sendRegisterRequest(this.details).then(() => {
          this.isLoading = false;
          if (this.isSuccess) {
            this.$router.push({
              name: "ConfirmVerify"
            }).catch(() => {});
          }

        });
      }
    }
  };
</script>
<style>
  .invalid-feedback {
    color: #ffff !important
  }
</style>